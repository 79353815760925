<template>
  <div id="result" class="wrapper">
    <main class="main">
      <div class="container">
        <div class="result">
          <nav class="btn-back" @click.stop="back()">
            <a href="#"><span class="sp-none">もどる</span></a>
          </nav>
          <nav
            :class="[
              'btn-auto-read',
              doSpeech ? 'active' : null,
              canSpeech ? null : 'disabled'
            ]"
            @click="toggleSpeech"
          >
            <a href="#">
              <span
                ><ruby><rb>読</rb><rt>よ</rt></ruby
                >み<ruby><rb>上</rb><rt>あ</rt></ruby
                >げ</span
              ></a
            >
          </nav>

          <div class="result-body">
            <div class="image">
              <img src="./img/result/img-stamp-m.png" />
              <p><img src="./img/result/reed-result.png" /></p>
            </div>
            <div class="time">
              <ruby><rb>時間</rb><rt>じかん</rt></ruby
              >は{{ mm }}:{{ ss }}でした
            </div>
            <div class="text" v-html="data.comment"></div>
            <a
              v-if="no + 1 < length"
              class="next"
              href="#"
              @click.prevent="next"
              ><span>
                <ruby><rb>次</rb><rt>つぎ</rt></ruby
                >の <ruby><rb>問題</rb><rt>もんだい</rt></ruby
                >へ
              </span></a
            >
            <template v-else>
              <a class="back" href="/ability/"
                ><span>
                  メニューへもどる
                </span></a
              >
              <a class="back" @click.stop="clickRanking()">
                <span>
                  ランキングを
                  <ruby><rb>見</rb><rt>み</rt></ruby>
                  る
                </span>
              </a>
              <p style="font-size: medium;text-align:center;">
                ランキングは6<ruby><rb>時</rb><rt>じ</rt></ruby
                >～21<ruby><rb>時</rb><rt>じ</rt></ruby
                >の15<ruby><rb>分</rb><rt>ふん</rt></ruby
                >ごとに <ruby><rb>更新</rb><rt>こうしん</rt></ruby
                >されるよ。
              </p>
            </template>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import back from '@/mixins/back';
export default {
  name: 'Result',
  components: {},
  mixins: [back],
  props: {
    title: String,
    id: String,
    no: Number,
    length: Number,
    mm: String,
    ss: String,
    data: Object
  },
  data() {
    return {
      canSpeech: false,
      doSpeech: false,
      speech: null
    };
  },
  mounted() {
    this.canSpeech = window.speechSynthesis;
    if (this.canSpeech) {
      this.speech = new SpeechSynthesisUtterance();
      this.speech.lang = 'ja-JP';
      this.speech.rate = 1.0;
      this.speech.pitch = 1.5;
      this.speech.volume = 1.0;
    }
  },
  methods: {
    async next() {
      this.$tryLoadingAsync(async () => {
        const next_no = this.no + 1;
        if (next_no < this.length) {
          const data = await this.$store.dispatch('api/getFacility', {
            id: this.id,
            no: next_no
          });
          this.$router
            .push({
              name: 'Question',
              params: {
                title: this.title,
                id: this.id,
                no: next_no,
                length: this.length,
                data: data
              }
            })
            .catch(() => {});
        }
      });
    },
    exec_speech() {
      if (this.doSpeech && this.canSpeech) {
        this.speech.text = this.data.speech_comment;
        speechSynthesis.speak(this.speech);
      }
    },
    toggleSpeech() {
      this.doSpeech = !this.doSpeech;
      this.exec_speech();
    },
    clickRanking() {
      this.$router
        .push({ name: 'Ranking', params: { id: this.id } })
        .catch(() => {});
    }
  }
};
</script>

<style></style>
